<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
.modal-title /deep/ {
  text-align: center !important;
  width: 100% !important;
  padding: auto !important;
}

/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}
</style>
<script>
import Layout from "@/views/layouts/main";
import CheckHeader from "@/components/check-header";
import {
  getCheckPowerPage,
  checkPowersToNames,
  deleteCheckPowerDetails,
} from "@/api/admin/exam/checkPower";
import addUserVue from "./add-user.vue";
import editUserVue from "./edit-user.vue";
import Vue from "vue";
Vue.prototype.checkPowersToNames = checkPowersToNames;
import changeExam from "@/views/pages/admin/examination/change-exam";
import YzSearch from "@/components/form/yzSearch.vue";
import {getNowExam} from "@/api/admin/exam/examRecord";
import changeMode from '../change-mode'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    CheckHeader,
    addUserVue,
    editUserVue,
    changeExam,
    YzSearch,
    changeMode,
  },
  data() {
    return {
      title: "考试报名信息审核  / ",
      subTitle: "切换报名",
      ksInfo: {},
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "审核人员",
          active: true,
        },
      ],
      pageData: {
        pageNum: 1,
        pageSize: 20,
      },
      dataList: [],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      chooseCid: "",
      search: [],


      isIndeterminate: false,
      checkAll: false,
      checkedCities: []
    };
  },
  methods: {
    //  获取审核人列表
    getList(isExport=false) {
      this.checkedCities = []
      this.pageData.ksbmbh = this.ksInfo.ksbmbh;
      this.pageData.shfl = 3;
      this.pageData.isExport = isExport;
      getCheckPowerPage(this.pageData).then((res) => {
        if (res.status) {
          this.dataList = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      });
    },
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页				
      this.getList() //渲染数据的函数
    },
    // 删除
    deleteCheckPowerDetails(cid) {
      this.$confirm(`是否确认删除该审核人员?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCheckPowerDetails(cid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    handleCheckedCitiesChange(){
      console.log(this.checkedCities)
    },
    handleCheckAllChange(e){
      this.isIndeterminate = e
      this.checkedCities = e?this.dataList:[]
    }
  },
  mounted() {
    getNowExam().then(res => {
      if (res.status) {
        this.ksInfo = res.data
        this.getList();
      }
    })
    this.search = [
      {
        title: "姓名/账号",
        bound: "keyword",
        type: "input",
      },
    ];
  },
};
</script>

<template>
  <Layout>
    <CheckHeader
      :title="title"
      :title2="ksInfo.ksmc"
      :subTitle="subTitle"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <changeMode :ks-info="ksInfo"></changeMode>
            <div class="pb-3 check-table-top">
              <div class="flexList" style="flex: 1">
                <yz-search
                  :data="search"
                  v-model="pageData"
                  @seachList="searchClick"
                ></yz-search>
                <b-button
                  variant="outline-info"
                  class="flexList mr-2 condition"
                  @click="$refs.addUser.show()"
                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                  >添加用户</b-button
                >
                <b-button
                    variant="outline-info"
                    class="flexList mr-2 condition"
                    @click="$refs.editUser.show(...checkedCities.map(i=>{return i.cid}))"
                ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                >批量修改权限</b-button
                >
                <b-button
                    variant="outline-info"
                    class="flexList mr-2 condition"
                    @click="deleteCheckPowerDetails(checkedCities.map(i=>{return i.cid}))"
                ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                >批量删除</b-button
                >
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                     @click="getList(true)"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
              </div>
            </div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">
                      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
                    </th>
                    <th style="width: 10%">审核编码</th>
                    <th style="width: 10%">审核单位</th>
                    <th style="width: 8%">登录账号</th>
                    <th style="width: 8%">用户姓名</th>
                    <th style="width: 8%">审核数</th>
                    <th style="width: 8%">报名起止序号</th>
                    <th>审核权限</th>
                    <th style="width: 3%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataList" :key="i">
                    <td>
                      <el-checkbox :label="item" :key="i">{{(pageData.pageNum - 1) * pageData.pageSize +  i + 1}}</el-checkbox>
                    </td>
                    <td>
                      {{ item.dwdm }}
                    </td>
                    <td>
                      <a href="" class="blue-font">{{ item.dwmc }}</a>
                    </td>
                    <td>{{ item.yhzh }}</td>
                    <td>{{ item.yhxm }}</td>
                    <td>{{ item.ysh ? item.ysh : 0 }}/{{ item.shrws }}</td>
                    <td>{{ item.shbmxhks }}-{{ item.shbmxhjs }}</td>
                    <td>{{ checkPowersToNames(item.shlb) }}</td>
                    <td class="tab-icon">
                      <a
                        href="javascript:;"
                        @click="
                          $refs.editUser.show(item.cid)
                        "
                        ><i
                          class="iconfont icon-edit-two text-dark align-middle mr-1"
                        ></i></a
                      ><i
                        @click="deleteCheckPowerDetails(item.cid)"
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </el-checkbox-group>
            <div class="float-right d-flex">
              <el-pagination
                @size-change="handleSizeChange"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum"
                :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next"
                :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <changeExam></changeExam>
    <add-user-vue ref="addUser" :ks-info="ksInfo"></add-user-vue>
    <edit-user-vue
      ref="editUser"
      @success="getList"
    ></edit-user-vue>
  </Layout>
</template>
