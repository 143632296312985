<template>
  <b-modal
    v-model="showUser"
    centered
    title="增加机构审核用户"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <yz-search
      :data="search"
      v-model="pageData"
      @seachList="getList"
    ></yz-search>
    <div
      class="table-responsive border mt-3"
      style="max-height: 300px; overflow-y: auto"
    >
      <table class="table light-table table-hover">
        <thead class="thead-light">
          <tr>
            <th style="width: 3%">选择</th>
            <th style="width: 10%">所属单位</th>
            <th style="width: 7%">登录账号</th>
            <th style="width: 6%">用户姓名</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, index) in tableList" :key="index">
            <td>
              <el-radio :label="obj" v-model="chooseOrgPerson" class="mr-1">{{
                index + 1
              }}</el-radio>
            </td>
            <td>{{ obj.dwmc }}</td>
            <td>
              <a
                href="javascript:;"
                @click="$yzAdminUserInfo().show(obj.yhzh)"
                class="font-blue"
                >{{ obj.yhzh }}</a
              >
            </td>
            <td>{{ obj.xm }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">审核范围</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseChecks">
          <el-checkbox
            v-for="(check, i) in checkPowerList"
            :label="check.id"
            :key="i"
            >{{ check.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div>
      序号{{ rangeInfo.minBmh }}-{{ rangeInfo.maxBmh }}，共{{
        rangeInfo.count
      }}人报名，已分配{{ rangeDisInfo.count }}人
    </div>
    <div>
      计划为用户 {{ chooseOrgPerson.xm }} 分配任务数：<el-input
        size="small"
        style="width: 80px"
        v-model="taskNum"
      ></el-input>
      序号{{ chooseMin }}-{{ chooseRange }}
    </div>
    <el-slider
      :min="chooseMin"
      :max="rangeInfo.maxBmh"
      :step="1"
      v-model="chooseRange"
    ></el-slider>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addUser">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import yzSearch from "@/components/form/yzSearch.vue";
import {getUserManagement} from "@/api/admin/user/institutionManage";
import {addCheckPower} from "@/api/admin/exam/checkPower";
import {
  getRegisterUserRange,
  getRegisterUserDisRange,
} from "@/api/admin/exam/checkPower";
export default {
  props: {
    ksInfo: {
      default: {}
    }
  },
  data() {
    return {
      checkAll: false,
      chooseChecks: [],
      chooseOrgPerson: {},
      checks: [
        {name: "报名资格审核", id: 1},
        {name: "报名照片审核", id: 2},
        {name: "学历审核", id: 3},
        {name: "导出报名审核", id: 4},
        {name: "修改审核结果", id: 5},
        {name: "锁定审核", id: 6},
      ],
      isIndeterminate: false,
      search: [],
      pageData: {},
      dict: {},
      tableList: [],
      rangeInfo: {},
      rangeDisInfo: {},
      chooseRange: 0,
      taskNum: 0,
      showUser: false,
    };
  },
  computed: {
    chooseMin() {
      return Math.min(Math.max(this.rangeDisInfo.maxBmh + 1, this.rangeInfo.minBmh), this.rangeInfo.maxBmh + 1)
    },
  },
  methods: {
    show(){
      getRegisterUserRange(this.ksInfo.ksbmbh).then((res) => {
        if (res.status) {
          this.rangeInfo = res.data;
          getRegisterUserDisRange(this.ksInfo.ksbmbh).then((res) => {
            if (res.status) {
              this.rangeDisInfo = res.data;
              this.chooseRange = this.chooseMin;
              this.showUser = true
            }
          });
        }
      });

    },
    hide(){
      this.showUser = false
    },
    getList() {
      this.pageData.shzt = 1
      this.pageData.pageSize = 9999
      getUserManagement("org", this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      });
    },
    addUser() {
      let form = {}
      form.jgmc = this.chooseOrgPerson.jgmc,
      form.jgdm = this.chooseOrgPerson.jgdm,
      form.dwdm = this.chooseOrgPerson.dwdm,
      form.dwmc =  this.chooseOrgPerson.dwmc,
      form.yhxm = this.chooseOrgPerson.xm;
      form.yhzh = this.chooseOrgPerson.yhzh;
      form.ksbmbh = this.ksInfo.ksbmbh;
      form.ksmc = this.ksInfo.ksmc;
      form.shbmxhks = this.chooseMin;
      form.shbmxhjs = this.chooseRange;
      form.shrws = this.taskNum;
      form.shfl = 3;
      if (
        this.chooseMin > this.chooseRange
      ) {
        this.$message({
          type: "warning",
          message: "新增失败，分配人数大于可分配人数！",
        });
        return;
      }
      form.shlb = this.chooseChecks.join(",");
      if (!this.chooseOrgPerson.yhzh) {
        this.$message({
          type: "warning",
          message: "请先选择用户!",
        });
        return;
      }
      addCheckPower([form]).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
        }
      });
    },
  },
  components: {
    yzSearch,
  },
  mounted() {
    this.getList();
    this.search = [
      {
        title: "姓名/账号/身份证件号",
        bound: "keyword",
        type: "input",
      },
    ];
  },
  watch: {
    taskNum(e) {
      this.chooseRange = Number(this.chooseMin) + Number(e) - 1;
    },
    chooseRange(e) {
      this.taskNum = Number(e) - Number(this.chooseMin) + 1;
    },
  },
};
</script>

<style></style>
